import * as R from 'ramda'
import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import Img from 'gatsby-image'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import 'custom-event-polyfill'

import { mapIndexed, notNilOrEmpty } from '../lib/Helpers'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Hero from '../components/Hero'
import BlockContent from '../components/BlockContent'
import ExhibitorsGrid from '../components/ExhibitorsGrid'

export default props => {
  const { data } = props
  const { edges: companies } = data.allSanityExhibitors
  const { sanityHomePage: homePage } = data
  const { sanitySiteSettings: settings } = data

  const [defaultOrder, setDefaultOrder] = React.useState([])
  const [selectedCategory, setSelectedCategory] = React.useState('')
  const [selectedVendorType, setSelectedVendorType] = React.useState('')
  const [defaultSort, setDefaultSort] = React.useState('asc')

  React.useEffect(() => {
    initialCompaniesOrder(companies)
  }, [])

  function onAutocompleteChange(event, value) {
    navigate(`/exhibitor/${value.node.slug.current}`)
  }

  function initialCompaniesOrder(comps) {
    R.compose(
      e => setDefaultOrder(e),
      R.sort(R.ascend(R.path(['node', 'title'])))
    )(comps)
  }

  function handleCategorySort(sort) {
    if (R.equals(sort, 'asc')) {
      R.compose(
        () => setDefaultSort(sort),
        e => setDefaultOrder(e),
        R.sort(R.ascend(R.path(['node', 'title'])))
      )(defaultOrder)
    } else {
      R.compose(
        () => setDefaultSort(sort),
        e => setDefaultOrder(e),
        R.sort(R.descend(R.path(['node', 'title'])))
      )(defaultOrder)
    }
  }

  return (
    <Layout>
      <SEO
        keywords={settings.keywords}
        description={settings.description}
        author={settings.author}
        title={settings.title}
        image={settings.seo_image.asset.url}
        url={settings.site_url}
      />
      <Hero
        video={homePage.hero_bg_video_url && homePage.hero_bg_video_url}
        img={homePage.hero_image && homePage.hero_image.asset.url}
        overlayImg={
          homePage.hero_overlay_image &&
          homePage.hero_overlay_image.asset.fluid.src
        }
        className="home-page"
        cta={homePage.ctaBtn}
        fetchApi={true}
      />
      <section className="container home-page-intro text--center">
        <h2 className="color--dark home-page-intro__sub-title">
          {homePage.intro_subtitle}
        </h2>
        <h1 className="color--text-primary home-page-intro__title">
          {homePage.intro_title}
        </h1>
      </section>
      <div className="container home-page-content">
        <section>
          {notNilOrEmpty(homePage.bodyContent_separator_img) && (
            <div className="text--center home-page-intro__img">
              <Img fixed={homePage.bodyContent_separator_img.asset.fixed} />
            </div>
          )}
          <h4 className="text--center home-page-intro__sub-title">
            {homePage.bodyContent_subtitle}
          </h4>
          <p className="text--center home-page-intro__title">
            {homePage.bodyContent_title}
          </p>
        </section>
        <section className="intro-text inner">
          <BlockContent blocks={homePage._rawBodyContent} />
        </section>
      </div>
      {notNilOrEmpty(homePage.ctas) && (
        <section className="intro-text container">
          <div className="inner section-cta">
            {mapIndexed((cta, i) => {
              return (
                <div className="section-cta--single" key={i}>
                  <img
                    src={cta.cta_image.asset.url}
                    alt={`icon for ${cta.title}`}
                  />
                  <div className="section-cta--single__content">
                    <span>
                      <strong>{cta.title} </strong>
                    </span>{' '}
                    {cta.bodyContent}
                  </div>
                </div>
              )
            })(homePage.ctas)}
          </div>
        </section>
      )}
      {homePage.help_desk_enabled && (
        <section className="intro-text section-helpdesk container">
          <h3 className="title">{homePage.help_desk_title}</h3>
          <BlockContent blocks={homePage._rawHelpDeskContent} />
          <div className="section-cta">
            {mapIndexed((cta, i) => {
              return (
                <div className="rect rect--black" key={i}>
                  <img
                    src={cta.cta_image.asset.url}
                    alt={`icon for ${cta.title}`}
                  />
                  <p className="section-cta--single__title text--center">
                    <strong>{cta.title}</strong>
                  </p>
                  <a
                    href={cta.url}
                    target="_blank"
                    className="section-cta--single__link text--center"
                  >
                    {cta.url_text}
                  </a>
                </div>
              )
            })(homePage.help_desk_ctas)}
          </div>
        </section>
      )}
      <ExhibitorsGrid />
    </Layout>
  )
}

export const query = graphql`
  query {
    sanitySiteSettings {
      author
      description
      keywords
      title
      site_url
      seo_image {
        asset {
          url
        }
      }
      logo {
        asset {
          fluid(maxHeight: 700) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    sanityHomePage {
      _rawBodyContent
      hero_bg_video_url
      hero_overlay_image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      hero_image {
        asset {
          url
        }
      }
      ctaBtn {
        cta_btn_text
        cta_btn_url
      }
      bodyContent_separator_img {
        asset {
          fixed(width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
      intro_title
      intro_subtitle
      _rawHelpDeskContent
      help_desk_enabled
      help_desk_title
      help_desk_ctas {
        url_text
        url
        title
        cta_image {
          asset {
            url
          }
        }
      }
    }
    allSanityVendorType {
      edges {
        node {
          title
          challenge_description
          challenge_hero_background {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          challenge_hero_overlay_image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          challenge_image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
    allSanityExhibitors {
      edges {
        node {
          title
          company_img {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 300) {
                ...GatsbySanityImageFixed
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
